<template>
  <div class="container grading">
    <el-dialog
      title="Keyboard"
      :visible.sync="changeKeyboard"
      :width="isPhone ? '90%' : '50%'"
      center
    >
      <div class="keyboard">
        <el-radio-group v-model="keyboard_select" class="row">
          <div class="col-md-6">
            <el-radio label="2">
              <img
                :class="keyboard_select === '2' ? 'keyboard_on' : ''"
                :src="keyboard_list[0]"
              />
            </el-radio>
          </div>
          <div class="col-md-6">
            <el-radio label="1">
              <img
                :class="keyboard_select === '1' ? 'keyboard_on' : ''"
                :src="keyboard_list[1]"
              />
            </el-radio>
          </div>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeKeyboard = false">取 消</el-button>
        <el-button type="primary" @click="saveKeyboard">
          確 定
        </el-button>
      </span>
    </el-dialog>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <a href="/tests">Tests</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <a href="/tests/grading">Grading</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item>SAT</el-breadcrumb-item>
        <!-- <el-breadcrumb-item>
          {{ sat_info.full_name }} ({{ sat_info.title }})
        </el-breadcrumb-item> -->
      </template>
    </Breadcrumb>
    <h3>Grading - {{ sat_info.full_name }} ({{ sat_info.title }})</h3>
    <el-divider></el-divider>
    <el-form label-width="120px">
      <el-form-item label="Keyboard:">
        <div class="row">
          <div class="col-md-3">
            <img :src="keyboard_url" style="width:100%" />
          </div>
          <div class="col-md-4">
            <el-button @click="changeKeyboard = true" size="small">
              <i class="fa fa-exchange-alt"></i>
              Change
            </el-button>
          </div>
        </div>
      </el-form-item>
      <div v-if="teacher_id > 0">
        <el-form-item label="Class:">
          <div class="row">
            <div class="col-md-3">
              <h4 style="margin-bottom:0;line-height:40px">
                {{ classInfo["title"] }}
              </h4>
            </div>
            <div class="col-md-4">
              <a
                :href="
                  `/tests/grading?type=groupCourse&teacher_id=${teacher_id}`
                "
              >
                <el-button size="small">
                  <i class="fa fa-exchange-alt"></i>
                  Change
                </el-button>
              </a>
            </div>
          </div>
        </el-form-item>
      </div>
      <div v-if="$route.query.user_exam_id === undefined">
        <el-form-item label="Students:" class="Students" v-if="canEdit">
          <div class="row">
            <div class="col-md-6">
              <el-select
                v-model="student"
                placeholder="Select student"
                filterable
                style="width:100%"
                @change="getResults"
                no-data-text="Loading..."
              >
                <el-option
                  v-for="student in students"
                  :key="student.id"
                  :label="
                    student.first_name +
                      ' ' +
                      student.last_name +
                      ' (' +
                      student.email +
                      ')'
                  "
                  :value="student.user_id"
                ></el-option>
              </el-select>
            </div>
            <div class="col-md-6">
              <el-select
                v-model="result"
                placeholder="Select results"
                filterable
                style="width:100%"
                @change="selectResults"
              >
                <el-option label="New Test" value="0"></el-option>
                <el-option
                  v-for="result in results"
                  :key="result.user_exam_id"
                  :label="result.date_taken + '(' + result.total_score + ')'"
                  :value="result.user_exam_id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="Students:" class="Students" v-else>
          <div class="row">
            <div class="col-md-6">
              <el-select
                v-model="result"
                placeholder="Select results"
                filterable
                style="width:100%"
                @change="selectResults"
              >
                <el-option label="New Test" value="0"></el-option>
                <el-option
                  v-for="result in results"
                  :key="result.user_exam_id"
                  :label="result.date_taken + '(' + result.total_score + ')'"
                  :value="result.user_exam_id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item label="Students:" class="Students" v-if="canEdit">
          <div class="row">
            <div class="col-md-6">
              <el-select
                v-model="student"
                placeholder="Select student"
                filterable
                style="width:100%"
                @change="getResults"
                no-data-text="Loading..."
              >
                <el-option
                  v-for="student in students"
                  :key="student.id"
                  :label="
                    student.first_name +
                      ' ' +
                      student.last_name +
                      ' (' +
                      student.email +
                      ')'
                  "
                  :value="student.user_id"
                ></el-option>
              </el-select>
            </div>
            <div class="col-md-6">
              <el-select
                v-model="user_exam_id"
                placeholder="Select results"
                filterable
                style="width:100%"
                @change="selectResults"
              >
                <el-option label="New Test" value="0"></el-option>
                <el-option
                  v-for="result in results"
                  :key="result.user_exam_id"
                  :label="result.date_taken + '(' + result.total_score + ')'"
                  :value="result.user_exam_id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="Student:" class="Students" v-else>
          <div class="row">
            <div class="col-md-6">
              <b>{{ this.profile.name }}</b>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="Exam taken:">
          <div class="row">
            <div class="col-md-6">
              <span class="pl-20">
                <el-date-picker
                  v-model="exam_date"
                  type="datetime"
                  placeholder="Exam taken"
                  value-format="yyyy-MM-dd HH:mm:ss"
                />
              </span>
            </div>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <div v-show="user_exam_id !== ''">
      <el-divider></el-divider>
      <!-- Reading -->
      <div>
        <h4>SECTION 1: Reading</h4>
        <el-input
          type="textarea"
          :rows="2"
          :maxlength="reading.count"
          show-word-limit
          placeholder="Please type or paste your answers here, and they will be auto-filled in the answer sheet below."
          v-model="reading.answerString"
          @input="setReading(reading.count)"
        >
        </el-input>
        <div class="row answer" v-if="reading.count > 0">
          <div class="col-md-3" v-for="col in readingStyle.col" :key="col">
            <div
              class="question"
              v-for="(num, index) in readingStyle.colCount"
              :key="num"
            >
              <div
                class="row"
                v-if="
                  reading.answerArr[reading.colCount * (col - 1) + index] !=
                    undefined
                "
              >
                <div class="col-sm-1-6 num">
                  <span>{{ reading.colCount * (col - 1) + num }}</span>
                </div>
                <div
                  class="col-sm-1-6 option"
                  v-for="option in reading.options"
                  :key="option"
                >
                  <el-radio
                    v-model="
                      reading.answerArr[reading.colCount * (col - 1) + index]
                        .answer
                    "
                    :label="option"
                  >
                    <span
                      :class="
                        reading.answerArr[reading.colCount * (col - 1) + index]
                          .answer == option
                          ? 'answer_active'
                          : ''
                      "
                      >{{ option }}</span
                    >
                  </el-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <!-- Writing and Language (Grammar) -->
      <div>
        <h4>SECTION 2: Writing and Language (Grammar)</h4>
        <el-input
          type="textarea"
          :rows="2"
          :maxlength="grammar.count"
          show-word-limit
          placeholder="Please type or paste your answers here, and they will be auto-filled in the answer sheet below."
          v-model="grammar.answerString"
          @input="setGrammar(grammar.count)"
        >
        </el-input>
        <div class="row answer" v-if="grammar.count > 0">
          <div class="col-md-3" v-for="col in grammarStyle.col" :key="col">
            <div
              class="question"
              v-for="(num, index) in grammarStyle.colCount"
              :key="num"
            >
              <div
                class="row"
                v-if="
                  grammar.answerArr[grammar.colCount * (col - 1) + index] !=
                    undefined
                "
              >
                <div class="col-sm-1-6 num">
                  <span>{{ grammar.colCount * (col - 1) + num }}</span>
                </div>
                <div
                  class="col-sm-1-6 option"
                  v-for="option in grammar.options"
                  :key="option"
                >
                  <el-radio
                    v-model="
                      grammar.answerArr[grammar.colCount * (col - 1) + index]
                        .answer
                    "
                    :label="option"
                  >
                    <span
                      :class="
                        grammar.answerArr[grammar.colCount * (col - 1) + index]
                          .answer == option
                          ? 'answer_active'
                          : ''
                      "
                      >{{ option }}</span
                    >
                  </el-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <!-- Math (No Calculator) -->
      <div>
        <h4>SECTION 3: Math (No Calculator)</h4>
        <el-input
          type="textarea"
          :rows="2"
          :maxlength="math.count"
          show-word-limit
          placeholder="Please type or paste your answers here, and they will be auto-filled in the answer sheet below."
          v-model="math.answerString"
          @input="setMath(math.count)"
        >
        </el-input>
        <div class="answer" v-if="math.count > 0">
          <div class="row" v-if="math.numCount > 0">
            <div class="col-md-3" v-for="col in mathStyle.col" :key="col">
              <div
                class="question"
                v-for="(num, index) in mathStyle.colCount"
                :key="num"
              >
                <div
                  class="row"
                  v-if="
                    math.answerArr[math.colCount * (col - 1) + index] !=
                      undefined
                  "
                >
                  <div class="col-sm-1-6 num">
                    <span>{{ math.colCount * (col - 1) + num }}</span>
                  </div>
                  <div
                    class="col-sm-1-6 option"
                    v-for="option in math.options"
                    :key="option"
                  >
                    <el-radio
                      v-model="
                        math.answerArr[math.colCount * (col - 1) + index][
                          'answer'
                        ]
                      "
                      :label="option"
                    >
                      <span
                        :class="
                          math.answerArr[math.colCount * (col - 1) + index][
                            'answer'
                          ] == option
                            ? 'answer_active'
                            : ''
                        "
                        >{{ option }}</span
                      >
                    </el-radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="math.numberAnswer.length > 0">
            <div
              class="col-md-1-5 numberAnswer"
              v-for="(col, index) in mathNumStyle.col"
              :key="col"
            >
              <div
                class="row <?='question'.$i?>"
                style="height: 25px;margin: 0 0 10px;"
              >
                <div class="col-sm-1-6 num">
                  <span>{{ col }}</span>
                </div>
                <div
                  class="col-sm-1-6 math_input"
                  v-for="num in mathNumStyle.colCount"
                  :key="num"
                >
                  <input
                    type="text"
                    maxlength="1"
                    v-model="math.numberAnswer[index].answer[num - 1]"
                    oninput="value=value.replace(/[^\d^\.\/]/g,'')"
                    :ref="'input_m_' + index + '_' + num"
                    @keyup="changefocus('m', index, num)"
                    @focus="focus($event)"
                  />
                </div>
              </div>
              <div
                class="col-sm-1-6"
                style="text-align: center;padding: 5px 0;"
              >
                <div
                  class="math_num"
                  v-for="option in math.numOptions"
                  :key="option"
                >
                  <span>{{ option }}</span>
                </div>
              </div>
              <div
                class="col-sm-1-6"
                style="text-align: center;padding: 5px 0;"
                v-for="num in mathNumStyle.colCount"
                :key="num"
              >
                <div
                  class="math_option"
                  v-for="option in math.numOptions"
                  :key="option"
                >
                  <el-radio
                    v-model="math.numberAnswer[index].answer[num - 1]"
                    :label="option"
                  >
                    <span
                      :class="
                        math.numberAnswer[index].answer[num - 1] == option
                          ? 'answer_active'
                          : ''
                      "
                    ></span>
                  </el-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <!-- Math (Calculator) -->
      <div>
        <h4>SECTION 4: Math (Calculator)</h4>
        <el-input
          type="textarea"
          :rows="2"
          :maxlength="mathc.count"
          show-word-limit
          placeholder="Please type or paste your answers here, and they will be auto-filled in the answer sheet below."
          v-model="mathc.answerString"
          @keyup.native="setMathc(mathc.count)"
        >
        </el-input>
        <div class="answer">
          <div class="row" v-if="mathc.count > 0">
            <div class="col-md-3" v-for="col in mathcStyle.col" :key="col">
              <div
                class="question"
                v-for="(num, index) in mathcStyle.colCount"
                :key="num"
              >
                <div
                  class="row"
                  v-if="
                    mathc.answerArr[mathc.colCount * (col - 1) + index] !=
                      undefined
                  "
                >
                  <div class="col-sm-1-6 num">
                    <span>{{ mathc.colCount * (col - 1) + num }}</span>
                  </div>
                  <div
                    class="col-sm-1-6 option"
                    v-for="option in mathc.options"
                    :key="option"
                  >
                    <el-radio
                      v-model="
                        mathc.answerArr[mathc.colCount * (col - 1) + index]
                          .answer
                      "
                      :label="option"
                    >
                      <span
                        :class="
                          mathc.answerArr[mathc.colCount * (col - 1) + index]
                            .answer == option
                            ? 'answer_active'
                            : ''
                        "
                        >{{ option }}</span
                      >
                    </el-radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="mathc.numCount > 0">
            <div
              class="col-md-3 numberAnswer"
              v-for="(col, index) in mathcNumStyle.col"
              :key="col"
            >
              <div
                class="row <?='question'.$i?>"
                style="height: 25px;margin: 0 0 10px;"
              >
                <div class="col-sm-1-6 num">
                  <span>{{ col }}</span>
                </div>
                <div
                  class="col-sm-1-6 math_input"
                  v-for="num in mathcNumStyle.colCount"
                  :key="num"
                >
                  <input
                    type="text"
                    maxlength="1"
                    v-model="mathc.numberAnswer[index].answer[num - 1]"
                    oninput="value=value.replace(/[^\d^\.\/]/g,'')"
                    :ref="'input_mc_' + index + '_' + num"
                    @keyup="changefocus('mc', index, num)"
                  />
                </div>
              </div>
              <div
                class="col-sm-1-6"
                style="text-align: center;padding: 5px 0;"
              >
                <div
                  class="math_num"
                  v-for="option in mathc.numOptions"
                  :key="option"
                >
                  <span>{{ option }}</span>
                </div>
              </div>
              <div
                class="col-sm-1-6"
                style="text-align: center;padding: 5px 0;"
                v-for="num in mathcNumStyle.colCount"
                :key="num"
              >
                <div
                  class="math_option"
                  v-for="option in mathc.numOptions"
                  :key="option"
                >
                  <el-radio
                    v-model="mathc.numberAnswer[index].answer[num - 1]"
                    :label="option"
                  >
                    <span
                      :class="
                        mathc.numberAnswer[index].answer[num - 1] == option
                          ? 'answer_active'
                          : ''
                      "
                    ></span>
                  </el-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div v-show="false">
        <!-- Essay -->
        <div>
          <el-radio-group v-model="hasEssay" class="hasEssay" size="small">
            <el-radio-button label="0">No Essay</el-radio-button>
            <el-radio-button label="1">Has Essay</el-radio-button>
          </el-radio-group>
          <div v-if="hasEssay == '1'">
            <h4>Section 5: Essay</h4>
            <div class="row essay-score" v-if="canEdit">
              <div class="col-md-4">
                Reading:
                <el-select
                  v-model="essayScore.essay_reading"
                  placeholder="Score"
                >
                  <el-option
                    v-for="item in [0, 2, 3, 4, 5, 6, 7, 8]"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="col-md-4">
                Analysis:
                <el-select
                  v-model="essayScore.essay_analysis"
                  placeholder="Score"
                >
                  <el-option
                    v-for="item in [0, 2, 3, 4, 5, 6, 7, 8]"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="col-md-4">
                Writing:
                <el-select
                  v-model="essayScore.essay_writing"
                  placeholder="Score"
                >
                  <el-option
                    v-for="item in [0, 2, 3, 4, 5, 6, 7, 8]"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <el-radio-group
              v-model="uploadImg"
              class="hasEssay"
              size="mini"
              style="margin-bottom:10px"
            >
              <el-radio-button label="1">上傳Essay圖片</el-radio-button>
              <el-radio-button label="0">手動輸入Essay</el-radio-button>
            </el-radio-group>
            <div v-show="uploadImg === '1'">
              <div>
                <el-upload
                  class="upload-demo"
                  drag
                  :action="postUrl"
                  :headers="token"
                  :data="{ path: 'results' }"
                  name="image"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <div>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将Essay文件/图片拖到此处，或<em>点击上传</em>
                    </div>
                  </div>
                </el-upload>
                <div style="display:flex">
                  <draggable :list="essay[0].answer.img_url" class="view-image">
                    <div
                      class="view-image-item"
                      v-for="(image, key) in essay[0].answer.img_url"
                      :key="key"
                    >
                      <el-image
                        style="width: 150px; height: 150px"
                        v-show="essay[0].answer.img_url.length > 0"
                        :src="essay[0].answer.img_url[key]"
                        :preview-src-list="essay[0].answer.img_url"
                      >
                      </el-image>
                      <div class="remove-icon">
                        <el-button
                          @click="removeImg(key)"
                          type="danger"
                          icon="el-icon-delete"
                          size="small"
                          circle
                        ></el-button>
                      </div>
                      <div class="number-icon">
                        <b>{{ key + 1 }}</b>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
            </div>
            <div v-show="uploadImg === '0'">
              <el-input
                v-model="essay[0].answer.essay"
                type="textarea"
                :rows="10"
                placeholder="Please type or paste your essay here."
              >
              </el-input>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
      </div>
      <div class="text-center">
        <el-button type="success" @click="postAnswer">Next</el-button>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import moment from "moment";
import TOEFL from "@/apis/tests.js";
import _ from "lodash";
import draggable from "vuedraggable";
import JwtService from "@/common/jwt.service";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  metaInfo() {
    return {
      title: "Grading - SAT"
    };
  },

  components: {
    Breadcrumb,
    draggable
  },

  mixins: [],

  props: [],
  data() {
    return {
      exam_date: null,
      token: { Authorization: `Bearer ${JwtService.getToken()}` },
      changeKeyboard: false,
      keyboard: "1",
      keyboard_select: "1",
      classIndex: null,
      classInfo: {},
      teacher_id: 0,
      hasEssay: "0",
      students: [],
      student: "",
      reading: {
        col: 0,
        colCount: 0,
        count: 0,
        answerArr: [],
        answerString: "",
        options: ["A", "B", "C", "D"]
      },
      grammar: {
        col: 0,
        colCount: 0,
        count: 0,
        answerArr: [],
        answerString: "",
        options: ["A", "B", "C", "D"]
      },
      math: {
        col: 0,
        colCount: 0,
        count: 0,
        answerArr: [],
        answerString: "",
        options: ["A", "B", "C", "D"],
        numCount: 0,
        numberAnswer: [],
        numOptions: ["/", ".", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
      },
      mathc: {
        col: 0,
        colCount: 0,
        count: 0,
        answerArr: [],
        answerString: "",
        options: ["A", "B", "C", "D"],
        numCount: 0,
        numberAnswer: [],
        numOptions: ["/", ".", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
      },
      essay: {
        answerString: [],
        answerUrl: ""
      },
      sat_info: {},
      questions: [],
      results: [],
      result: [],
      user_exam_id: "",
      essayScore: {
        essay_reading: 0,
        essay_analysis: 0,
        essay_writing: 0
      },
      uploadImg: "1"
    };
  },
  computed: {
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    ...mapState("user", ["profile"]),
    isClass() {
      let isClass = true;
      if (this.$route.query.isClass) {
        isClass = true;
      } else {
        isClass = false;
      }
      return isClass;
    },
    canEdit() {
      let canEdit = false;
      if (this.profile.all_roles != undefined) {
        if (
          this.profile.all_roles.indexOf("super-admin") > -1 ||
          this.profile.all_roles.indexOf("staff") > -1 ||
          this.profile.all_roles.indexOf("teacher") > -1
        ) {
          canEdit = true;
        } else {
          canEdit = false;
        }
      }
      return canEdit;
    },
    keyboard_url() {
      let url = "";
      if (this.keyboard == 1) {
        url =
          "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/tests/SAT_type2.jpg";
      } else {
        url =
          "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/tests/SAT_type1.jpg";
      }
      return url;
    },
    keyboard_list() {
      let url = [];
      url[0] =
        "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/tests/SAT_type1.jpg";
      url[1] =
        "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/tests/SAT_type2.jpg";
      return url;
    },
    readingStyle() {
      return this.getStyle(this.reading.col, this.reading.colCount);
    },
    grammarStyle() {
      return this.getStyle(this.grammar.col, this.grammar.colCount);
    },
    mathStyle() {
      return this.getStyle(this.math.col, this.math.colCount);
    },
    mathNumStyle() {
      let style = {
        col: [],
        colCount: []
      };
      for (let i = 1; i <= this.math.numCount; i++) {
        style.col.push(i + this.math.count);
      }
      for (let i = 1; i <= 4; i++) {
        style.colCount.push(i);
      }
      return style;
    },
    mathcStyle() {
      return this.getStyle(this.mathc.col, this.mathc.colCount);
    },
    mathcNumStyle() {
      let style = {
        col: [],
        colCount: []
      };
      for (let i = 1; i <= this.mathc.numCount; i++) {
        style.col.push(i + this.mathc.count);
      }
      for (let i = 1; i <= 4; i++) {
        style.colCount.push(i);
      }
      return style;
    },
    exam_id() {
      return this.$route.query.exam_id;
    },
    postUrl() {
      return process.env.VUE_APP_BACKEND_URL + "image";
    },
    moment() {
      return moment();
    }
  },
  watch: {
    "reading.answerArr": {
      handler(val) {
        let arr = this.textChange(val, this.keyboard, this.reading.count);
        this.reading.answerString = arr.join("");
      },
      deep: true
    },
    "grammar.answerArr": {
      handler(val) {
        let arr = this.textChange(val, this.keyboard, this.grammar.count);
        this.grammar.answerString = arr.join("");
      },
      deep: true
    },
    "math.answerArr": {
      handler(val) {
        let arr = this.textChange(val, this.keyboard, this.math.count);
        this.math.answerString = arr.join("");
      },
      deep: true
    },
    "mathc.answerArr": {
      handler(val) {
        let arr = this.textChange(val, this.keyboard, this.mathc.count);
        this.mathc.answerString = arr.join("");
      },
      deep: true
    },
    profile() {
      if (
        this.profile.all_roles.indexOf("super-admin") > -1 ||
        this.profile.all_roles.indexOf("staff") > -1 ||
        this.profile.all_roles.indexOf("teacher") > -1
      ) {
        if (this.$route.query.teacher_id > 0) {
          this.getGroupCourses(this.$route.query.teacher_id);
        } else {
          this.getStudents();
        }
      } else {
        this.getResults(this.profile.id);
      }
    }
  },

  async mounted() {
    if (this.$route.query.user_exam_id) {
      this.user_exam_id = parseInt(this.$route.query.user_exam_id);
    }
    if (this.$route.query.teacher_id) {
      this.teacher_id = parseInt(this.$route.query.teacher_id);
    }
    if (this.$route.query.classIndex !== null) {
      this.classIndex = this.$route.query.classIndex;
    }
    if (this.$route.query.teacher_id > 0) {
      this.getGroupCourses(this.$route.query.teacher_id);
    }
    if (this.$route.query.user_id && this.$route.query.exam_id) {
      this.student = parseInt(this.$route.query.user_id);
      const results = await TOEFL.getResults(this.exam_id, {
        user_id: this.student
      });
      this.results = results.user_exams;
      this.exam_date = moment().format("YYYY-MM-DD 09:00:00");
    }
    if (
      this.$route.query.user_id &&
      this.$route.query.user_exam_id === undefined
    ) {
      this.student = parseInt(this.$route.query.user_id);
      this.getResults(this.student);
    }
    this.getProfile();
    this.getExamInfo();
    this.keyboard = this.$route.query.keyboard;
    this.keyboard_select = this.$route.query.keyboard;
    if (this.user_exam_id > 0) {
      this.editResult(this.user_exam_id);
    }
    window.addEventListener("keydown", event => {
      if (event.keyCode === 9) {
        event.preventDefault();
      }
    });
  },
  methods: {
    async getExamInfo() {
      const info = await TOEFL.getExamInfo(this.exam_id);
      this.sat_info = info.exam;
    },
    async getStudents() {
      if (this.students.length <= 0) {
        const students = await TOEFL.getStudents();
        this.students = students;
      }
    },
    async getResults(e) {
      const results = await TOEFL.getResults(this.exam_id, {
        user_id: e
      });
      this.results = results.user_exams;
      if (this.results.length > 0) {
        this.selectResults(this.results[0].user_exam_id);
        this.result = this.results[0].user_exam_id;
        this.$router.replace({
          name: "GradingSAT",
          query: {
            keyboard: this.keyboard,
            exam_id: this.exam_id,
            user_id: this.student,
            user_exam_id: this.results[0].user_exam_id,
            classIndex: this.classIndex,
            teacher_id: this.teacher_id
          }
        });
      } else {
        this.startGrading();
        this.result = "0";
      }
    },
    async editResult(user_exam_id) {
      this.user_exam_id = user_exam_id;
      let res = await TOEFL.getOldExam(user_exam_id);
      this.essayScore = res.essayScore;
      //Reading
      let reading_arr = res.questions["reading"];
      this.reading.colCount = 13;
      this.reading.col = Math.ceil(reading_arr.length / this.reading.colCount);
      this.reading.count = reading_arr.length;
      this.reading.answerArr = reading_arr;

      //Grammar
      let writing_arr = res.questions["writing"];
      this.grammar.colCount = 11;
      this.grammar.col = Math.ceil(writing_arr.length / this.grammar.colCount);
      this.grammar.count = writing_arr.length;
      this.grammar.answerArr = writing_arr;

      //Math
      let math_arr = res.questions["math"];
      let new_math_arr = [];
      let new_math_num = [];
      math_arr.forEach(function(val) {
        if (val.is_num == 0) {
          new_math_arr.push(val);
        } else {
          let answer = val["answer"];
          let answer_arr = [];
          if (answer !== "") {
            answer = answer + "";
            answer_arr = answer.split("");
          }
          val["answer"] = answer_arr;
          new_math_num.push(val);
        }
      });
      this.math.colCount = 4;
      this.math.col = Math.ceil(new_math_arr.length / this.math.colCount);

      this.math.count = new_math_arr.length;
      this.math.numCount = new_math_num.length;
      this.math.answerArr = new_math_arr;
      this.math.numberAnswer = new_math_num;

      //MathC
      let mathc_arr = res.questions["math calculator"];
      let new_mathc_arr = [];
      let new_mathc_num = [];
      mathc_arr.forEach(function(val) {
        if (val.is_num == 0) {
          new_mathc_arr.push(val);
        } else {
          let answer = val["answer"];
          let answer_arr = [];
          if (answer !== "") {
            answer = answer + "";
            answer_arr = answer.split("");
          }
          val["answer"] = answer_arr;
          new_mathc_num.push(val);
        }
      });
      this.mathc.colCount = 8;
      this.mathc.col = Math.ceil(new_mathc_arr.length / this.mathc.colCount);

      this.mathc.count = new_mathc_arr.length;
      this.mathc.numCount = new_mathc_num.length;
      this.mathc.answerArr = new_mathc_arr;
      this.mathc.numberAnswer = new_mathc_num;

      //essay
      let essay_arr = res.questions["essay"];
      if (!_.isArray(essay_arr[0].answer.img_url)) {
        if (essay_arr[0].answer.img_url == "") {
          essay_arr[0].answer.img_url = [];
        } else {
          essay_arr[0].answer.img_url = [essay_arr[0].answer.img_url];
        }
      }

      if (
        essay_arr[0].answer.img_url.length > 0 ||
        essay_arr[0].answer.essay !== ""
      ) {
        this.hasEssay = 1;
      }
      this.essay = essay_arr;
    },
    async startGrading() {
      let res = {};
      if (this.canEdit) {
        res = await TOEFL.getNewExam(this.exam_id, {
          is_create: 0,
          user_id: this.student
        });
      } else {
        res = await TOEFL.getNewExam(this.exam_id, {
          is_create: 0
        });
      }
      this.user_exam_id = res.user_exam_id;
      this.$router.replace({
        name: "GradingSAT",
        query: {
          keyboard: this.keyboard,
          exam_id: this.exam_id,
          user_id: this.student,
          user_exam_id: res.user_exam_id,
          classIndex: this.classIndex,
          teacher_id: this.teacher_user_id
        }
      });
    },
    selectResults(e) {
      if (e > 0) {
        this.editResult(e);
      } else {
        this.startGrading();
      }
    },
    removeImg(key) {
      this.essay[0].answer.img_url.splice(key, 1);
    },
    handleAvatarSuccess(res) {
      this.essay[0].answer.img_url.push(res.image.url);
    },
    beforeAvatarUpload() {
      let canUpload = true;
      if (this.essay[0].answer.img_url.length == 4) {
        canUpload = false;
        this.$message("圖片不能超過4張！");
      }
      return canUpload;
    },
    saveKeyboard() {
      if (this.keyboard == this.keyboard_select) {
        this.keyboard = this.keyboard_select;
      } else {
        this.keyboard = this.keyboard_select;
        this.reading.answerString = this.textChange(
          this.reading.answerArr,
          this.keyboard,
          this.reading.count
        ).join("");
        this.grammar.answerString = this.textChange(
          this.grammar.answerArr,
          this.grammar,
          this.grammar.count
        ).join("");
        this.math.answerString = this.textChange(
          this.math.answerArr,
          this.keyboard,
          this.math.count
        ).join("");
        this.mathc.answerString = this.textChange(
          this.mathc.answerArr,
          this.keyboard,
          this.mathc.count
        ).join("");
      }
      this.changeKeyboard = false;
    },
    replaceAnswer(arr, value) {
      let newArr = [];
      if (value == "1") {
        _(arr).forEach(function(answer) {
          if (answer === "S") {
            newArr.push("B");
          } else if (answer === "D") {
            newArr.push("C");
          } else if (answer === "F") {
            newArr.push("D");
          } else {
            newArr.push(answer);
          }
        });
      } else if (value == "2") {
        _(arr).forEach(function(answer) {
          if (answer === "B") {
            newArr.push("S");
          } else if (answer === "C") {
            newArr.push("D");
          } else if (answer === "D") {
            newArr.push("F");
          } else {
            newArr.push(answer);
          }
        });
      }
      return newArr;
    },
    focus(event) {
      event.currentTarget.select();
    },
    getStyle(col, colCount) {
      let style = {
        col: [],
        colCount: []
      };
      for (let i = 1; i <= col; i++) {
        style.col.push(i);
      }
      for (let i = 1; i <= colCount; i++) {
        style.colCount.push(i);
      }
      return style;
    },
    setReading(count) {
      //输入影响布局
      this.reading.answerString = this.reading.answerString.toLocaleUpperCase();
      let string = "";
      string = this.reading.answerString;
      let changeArr = string.split("");
      this.keyboardChange(changeArr, this.keyboard, this.reading.answerArr);
      if (changeArr.length >= count) {
        let next_section = document.getElementsByClassName(
          "el-textarea__inner"
        )[1];
        next_section.focus();
      }
    },
    setGrammar(count) {
      //输入影响布局
      this.grammar.answerString = this.grammar.answerString.toLocaleUpperCase();
      let string = "";
      string = this.grammar.answerString;
      let changeArr = string.split("");
      this.keyboardChange(changeArr, this.keyboard, this.grammar.answerArr);
      if (changeArr.length >= count) {
        let next_section = document.getElementsByClassName(
          "el-textarea__inner"
        )[2];
        next_section.focus();
      }
    },
    setMath(count) {
      //输入影响布局
      this.math.answerString = this.math.answerString.toLocaleUpperCase();
      let string = "";
      string = this.math.answerString;
      let changeArr = string.split("");
      this.keyboardChange(changeArr, this.keyboard, this.math.answerArr);
      if (changeArr.length >= count) {
        this.$refs["input_m_0_1"][0].focus();
      }
      // this.math.answerArr = arr;
    },
    setMathc(count) {
      //输入影响布局
      this.mathc.answerString = this.mathc.answerString.toLocaleUpperCase();
      let string = "";
      string = this.mathc.answerString;
      let changeArr = string.split("");
      this.keyboardChange(changeArr, this.keyboard, this.mathc.answerArr);
      if (changeArr.length >= count) {
        this.$refs["input_mc_0_1"][0].focus();
      }
    },
    textChange(arr, type, count) {
      let newArr = [];
      if (type == "2") {
        _(arr).forEach(function(answer, index) {
          answer = answer.answer;
          if (index < count) {
            if (answer === undefined) {
              newArr.push("X");
            } else {
              if (answer === "A") {
                newArr.push("A");
              } else if (answer === "B") {
                newArr.push("S");
              } else if (answer === "C") {
                newArr.push("D");
              } else if (answer === "D") {
                newArr.push("F");
              } else if (answer === "") {
                newArr.push("");
              } else {
                newArr.push("X");
              }
            }
          }
        });
      } else {
        _(arr).forEach(function(answer, index) {
          answer = answer.answer;
          if (index < count) {
            if (answer === undefined) {
              newArr.push("X");
            } else if (answer === "") {
              newArr.push("");
            } else {
              newArr.push(answer);
            }
          }
        });
      }
      return newArr;
    },
    keyboardChange(arr, type, ansArr) {
      let newArr = [];
      if (type == "2") {
        _(ansArr).forEach(function(item, index) {
          let answer = arr[index];
          if (answer == undefined) {
            answer = "";
          }
          if (answer === "A") {
            ansArr[index].answer = "A";
          } else if (answer === "S") {
            ansArr[index].answer = "B";
          } else if (answer === "D") {
            ansArr[index].answer = "C";
          } else if (answer === "F") {
            ansArr[index].answer = "D";
          } else if (answer === "") {
            ansArr[index].answer = "";
          } else {
            ansArr[index].answer = "X";
          }
        });
      } else {
        _(ansArr).forEach(function(item, index) {
          let answer = arr[index];
          if (answer == undefined) {
            answer = "";
          }
          if (
            answer === "A" ||
            answer === "B" ||
            answer === "C" ||
            answer === "D" ||
            answer === ""
          ) {
            ansArr[index].answer = answer;
          } else {
            ansArr[index].answer = "X";
          }
        });
      }
      return newArr;
    },
    changefocus(section, index, num) {
      let string = "";
      let keyCode = event.keyCode;
      if (keyCode == 8 || keyCode == 37) {
        if (num == 1) {
          index = index - 1;
          num = 5;
        }
        string = "input_" + section + "_" + index + "_" + (num - 1);
      } else {
        if (num == 4) {
          index = index + 1;
          num = 0;
        }
        string = "input_" + section + "_" + index + "_" + (num + 1);
      }
      let length = 0;
      if (section === "m") {
        length = this.math.numberAnswer.length;
      }
      if (section === "mc") {
        length = this.mathc.numberAnswer.length;
      }
      if (index < length) {
        this.$refs[string][0].focus();
      } else {
        if (section === "m") {
          let next_section = document.getElementsByClassName(
            "el-textarea__inner"
          )[3];
          next_section.focus();
        }
      }
    },
    postAnswer() {
      if (this.exam_date == null || this.exam_date === "") {
        this.$message({
          message: "請填寫您的考試日期後再提交。",
          type: "warning"
        });
        return false;
      }
      let reading = this.reading.answerArr;
      let writing = this.grammar.answerArr;
      let math = this.math.answerArr;
      let mathNum = this.math.numberAnswer;
      let mathc = this.mathc.answerArr;
      let mathcNum = this.mathc.numberAnswer;
      let essay = this.essay;
      let mathNum_new = [];
      let mathcNum_new = [];

      mathNum.forEach(function(val) {
        let value = val;
        if (value != "") {
          value.answer = val.answer.join("");
          mathNum_new.push(value);
        }
      });
      mathcNum.forEach(function(val) {
        let value = val;
        if (value != "") {
          value.answer = val.answer.join("");
          mathcNum_new.push(value);
        }
      });
      let newArr = [];
      newArr = reading
        .concat(writing)
        .concat(math)
        .concat(mathNum_new)
        .concat(mathc)
        .concat(mathcNum_new)
        .concat(essay);
      this.gradeScore(newArr);
    },
    async gradeScore(answers) {
      await TOEFL.gradeScore(this.user_exam_id, {
        exam_question_id: 0,
        progress_remaining: 0,
        answers: answers,
        essayScore: this.essayScore,
        exam_date: this.exam_date
      });
      console.log(this.user_exam_id);
      // return;
      // this.routerRe({
      //   name: "TestResult",
      //   query: {
      //     user_exam_id: this.user_exam_id
      //   },
      //   params: {
      //     testType: "sat"
      //   }
      // });
      window.location.href = "/sat/result?user_exam_id=" + this.user_exam_id;
    },
    async getGroupCourses(teacher_uid) {
      const res = await TOEFL.getGroupCourses({
        teacher_id: teacher_uid
      });
      this.classInfo = res.session_classes[this.classIndex];
      this.students = res.session_classes[this.classIndex].students;
    },
    ...mapActions("user", ["getProfile"])
  }
};
</script>

<style scoped>
.grading {
  padding-bottom: 20px;
}
.question >>> .el-radio__label,
.math_option >>> .el-radio__label {
  padding: 0;
  display: block;
}
.question >>> .el-radio__input,
.math_option >>> .el-radio__input {
  display: none;
}

.question >>> .el-radio,
.math_option >>> .el-radio {
  width: 100%;
  margin-bottom: 0;
}

.col-xs-1-5,
.col-sm-1-5,
.col-md-1-5,
.col-lg-1-5 {
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  width: 20%;
  float: left;
}
.col-xs-1-6,
.col-sm-1-6,
.col-md-1-6,
.col-lg-1-6 {
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  width: 16.6666666666667%;
  float: left;
}
.question {
  height: 25px;
  margin: 0 0 10px;
}
.answer {
  margin-top: 20px;
}

.numberAnswer {
  margin-top: 20px;
}

.question .option,
.answer_input,
.num,
.math_input {
  font-size: 14px;
  height: 25px;
  line-height: 23px;
  padding: 0;
  text-align: center;
}
.math_input input {
  font-size: 18px;
}
.question .option:hover,
.math_option:hover {
  cursor: pointer;
}

.question .option:hover span,
.math_option:hover span {
  background-color: #17a2b8;
  color: white;
  border-color: #17a2b8;
}
.question .option span,
.math_option span {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 21px;
  border: solid 2px #42a16a;
  border-radius: 50%;
  font-weight: 500;
}

.math_num {
  text-align: center;
  height: 31px;
}
.math_num span {
  display: inline-block;
  width: 30px;
  height: 31px;
  font-weight: 500;
}

.math_option,
.math_num {
  margin: 5px 0;
}
.answer_input input,
.math_input input {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
}
.answer_active {
  background-color: #42a16a;
  color: white;
}
.num span {
  font-weight: 700;
}
.math_num span {
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
}

.math {
  margin-top: 30px;
}
.kbd_type img {
  height: 50px;
}
.kbd_type .type1 img:hover,
.kbd_type .type2 img:hover {
  opacity: 1;
}
.kbd_selected {
  opacity: 1;
}
.kbd_no {
  opacity: 0.2;
}
.submit {
  margin-bottom: 20px;
}
.submit button {
  float: right;
}
.btn:hover {
  background: #337ab7;
  border-color: #337ab7;
  opacity: 0.8;
}
.error {
  border-color: red;
}

.essay_toggle:hover {
  cursor: pointer;
}
.essay_score {
  width: 60px;
  height: 30px;
  margin-bottom: 0px;
}

.essay-score {
  margin-top: 15px;
  margin-bottom: 15px;
}
.keyboard img {
  width: 100%;
  opacity: 0.2;
}
.keyboard >>> .el-radio__label {
  padding: 0;
}
.keyboard >>> .el-radio__input {
  display: none;
}
img.keyboard_on {
  opacity: 1;
}
.view-image {
  margin: 10px 0;
  display: flex;
}
.view-image-item {
  position: relative;
  margin-right: 15px;
}
.view-image-item >>> img {
  border-radius: 5px;
  border: 1px solid #ccc;
}
.view-image-item:hover .remove-icon {
  display: block;
}
.remove-icon {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}
.number-icon {
  position: absolute;
  color: #fff;
  background-color: #42a16a;
  text-align: center;
  line-height: 32px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  bottom: 16px;
  left: 10px;
}

@media screen and (max-width: 736px) {
  .col-md-1-5 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  ::v-deep .el-upload-dragger {
    width: 100%;
  }
}
@media screen and (max-width: 736px) {
  .question {
    height: 45px;
  }
  .question .option,
  .question .num {
    font-size: 16px;
    height: 45px;
    line-height: 43px;
  }
  .question .option span {
    width: 45px;
    height: 45px;
    line-height: 41px;
    font-size: 16px;
  }
}

</style>
